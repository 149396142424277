import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button'

const STATUS = {
    NEW: 'new',
    WAITING_CONFIRMATION: 'waitingConfirmation'
}

const COUNTDOWN = 10;

function ConfirmButton({children, confirmationMsg, onClick, disabled}) {

    const [status, setStatus] = useState(STATUS.NEW);
    const [count, setCount] = useState(COUNTDOWN)

    useEffect(() => {
        if(status == STATUS.WAITING_CONFIRMATION) {
            if(count > 0) {
                const timeout = setTimeout(() => {
                    setCount(count - 1);
                }, 1000)

                return function() {
                    clearTimeout(timeout);
                }

            } else {
                setStatus(STATUS.NEW);
            } 
        } else {
            if(count != COUNTDOWN) {
                setCount(COUNTDOWN);
            }
        }
    }, [status, count])

    function handleClick(e) {
        if(status == STATUS.NEW) {
            setStatus(STATUS.WAITING_CONFIRMATION)
        } else {            
            setStatus(STATUS.NEW)
            if(onClick) {
                onClick(e)
            }
        }
    }

    function ButtonWithStatus() {
        return status == STATUS.NEW ? 
        (
            <Button 
            variant="contained"
            onClick={handleClick}
            color="primary">{children}</Button>
        ) :
        (
            <Button
            variant="contained"
            onClick={handleClick}
            color="secondary">{confirmationMsg || children} - ({count})</Button>
        )
    }

    return <ButtonWithStatus/>

}

export default ConfirmButton;