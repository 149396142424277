import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment'
import Button from '@material-ui/core/Button'
import Box from '@material-ui/core/Box';
import DayPicker from '../shared/DayPicker';
import {Link} from 'react-router-dom';
import Config from '../shared/Config'
import ConfirmButton from '../shared/ConfirmButton'

function momentToString(date) {
    return moment(date).format('YYYY-MM-DD');
}

function Cierre({empresaId, start, end}) {

    const [cierreId, setCierreId] = useState(null)
    // const [cierreDates, setCierreDates] = useState(moment(date))
    const [cierreRange, setCierreRange] = useState({
        start: momentToString(start),
        end: momentToString(end)
    })
    const [working, setWorking] = useState(true);

    async function fetchCierreId() {
       setWorking(true);
       const result = await axios.get(`${Config.API_URL}/cierres/find?empresaId=${empresaId}&date=${cierreRange.end}`);
       setCierreId(result.data.error ? null : result.data.id)
       setWorking(false);
    }    

    useEffect(() => {   
        setCierreRange({
            start: momentToString(start),
            end: momentToString(end)
        })           
    }, [start, end])

    useEffect(() => {
        fetchCierreId();        
    }, [cierreRange])

    function handleStartDayChange(value) {
        setCierreRange({...cierreRange, start: value})
    }

    function handleEndDayChange(value) {    
        setCierreRange({...cierreRange, end: value})
    }    

    async function generateCierre() {
        setWorking(true);
        const result = await axios.post(`${Config.API_URL}/cierres/generate`, {
            start: cierreRange.start,
            end: cierreRange.end,
            empresaId
        })
        await fetchCierreId();
    }

    async function deleteCierre() {
        setWorking(true);
        const result = await axios.post(`${Config.API_URL}/cierres/delete`, {
            end: cierreRange.end,
            empresaId
        })
        await fetchCierreId();         
    }

    return (
        <div>
            {
                cierreId ? 
                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                    <p style={{textAlign:'right'}}>Ya existe cierre</p>
                    <Button 
                    style={{margin: '0 10px'}}
                    variant="contained" color="primary" 
                    disabled={working}
                    component={Link}
                    target="_blank"
                    to={`/instance/${empresaId}/cierre/${cierreId}`}>Ver</Button>
                    <ConfirmButton
                        style={{margin: '0 10px'}}
                        confirmationMsg="¿Eliminar cierre?"
                        disabled={working}
                        onClick={deleteCierre}>
                        Eliminar
                    </ConfirmButton>
                    
                </Box>
                :
                <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center">
                    <span>Inicio: </span>
                    <DayPicker 
                        pastMonth={true}
                        date={momentToString(start)} 
                        style={{margin:'0 10px'}}
                        day={cierreRange.start}
                        onDayChange={handleStartDayChange}/>
                    <span>Fin: </span>
                    <DayPicker 
                        date={momentToString(end)} 
                        style={{margin:'0 10px'}}
                        day={cierreRange.end}
                        onDayChange={handleEndDayChange}/>
                    <Button variant="contained" color="primary" 
                        disabled={working}
                        onClick={generateCierre}>Generar cierre</Button>
                </Box>
            }
        </div>
    )

}

export default Cierre;