
import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import FormControl from '@material-ui/core/FormControl';
import Config from '../shared/Config'

function usePlans() {

    const [plans, setPlans] = useState([])

    useEffect(() => {        
        const fetchPlans = async () => {
            const result = await axios.get(`${Config.API_URL}/planes`);
            setPlans(result.data)
        }
        fetchPlans();        
    }, [])

    return plans;
}

function PlanPicker({value, onChange, disabled}) {
    const plans = usePlans();

    return (
        <FormControl disabled={disabled}>
            <Select value={value}                
                onChange={onChange}>
                {
                    plans.map(p => (
                        <MenuItem key={p.id} value={p.id}>{p.name}</MenuItem>
                    ))
                }
            </Select>
        </FormControl>        
    )
}

export default PlanPicker;