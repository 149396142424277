import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { withStyles } from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import { Paper, TextField } from '@material-ui/core'
import { IconButton } from '@material-ui/core'
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import moment from 'moment'
import useInstance from '../shared/hooks/useInstance'
import Chart from '../shared/Chart'
import PlanPicker from '../shared/PlanPicker';
import Cierre from './Cierre';
import Config from '../shared/Config'
import ConfirmButton from '../shared/ConfirmButton';

const months = [
    'Enero', 'Febrero', 'Marzo', 'Abril', 'Mayo', 'Junio', 'Julio', 'Agosto', 'Septiembre', 'Octubre', 'Noviembre', 'Diciembre'
]

const styles = theme => ({
    root: {
        width: '80%',
        margin: 'auto',
        marginTop: '50px'
    },
    form: {
        display: 'flex',
        flexWrap: 'wrap',
        marginBottom: '40px'
    },
    header: {
        marginBottom: '20px'
    }
})

const dataTypeMap = {
    'completados':'Activos',
    'enProceso':'En proceso',
    'bajas':'Bajas'
}

function CurrentMonth({date}) {
    const current = moment.utc(date);
    const month = months[current.month()]
    return (
        <span>{month} - {current.year()}</span>
    )
}
function getCurrentMonthRange() {
    const today = moment.utc();
    return {
        end: today.endOf('month').format('YYYY-MM-DD'),
        start: today.startOf('month').format('YYYY-MM-DD')       
    }
}

function Instance(props) {

    const [working, setWorking] = useState(false);
    const [instance, setInstance] = useInstance(props.match.params.id);    
    const [data, setData] = useState([])
    const [query, setQuery] = useState({
        dataType: 'completados',
        ...getCurrentMonthRange()
    })
    const {classes} = props;    

    useEffect(() => {
        const fetchData = async () => {            
            const result = await axios.get(`${Config.API_URL}/api/employees?empresaId=${props.match.params.id}&from=${query.start}&to=${query.end}`)
            setData(result.data.map(r => ({
                ...r,
                day: moment(r.date).date()
            })))
        }
        
        fetchData()
    }, [query])

    useEffect(() => {
        if(instance && instance.deleted) {
            props.history.push('/')
        }        
    }, [instance])

    function changeMonth(change) {
        const changed = moment.utc(query.start).add(change, 'month');
        const start = changed.startOf('month').format('YYYY-MM-DD')
        const end = changed.endOf('month').format('YYYY-MM-DD')
        setQuery({
            ...query,
            start, end
        })
    }

    async function handlePlanChange(e) {
        setWorking(true);
        const planId = e.target.value;
        await axios.post(`${Config.API_URL}/empresas/${props.match.params.id}/plan`, {
            planId 
        })
        setInstance({...instance, planId})
        setWorking(false);
    }

    async function handleDeleteEmpresa(e) {
        setWorking(true);
        const empresaId = props.match.params.id;
        await axios.post(`${Config.API_URL}/empresas/${empresaId}/delete`);
        setWorking(false);
        setInstance({...instance, deleted:true});
    }

    return (
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant="h3" gutterBottom>
                    {instance.name}
                </Typography>
                <p>Fecha creación: {moment(instance.createdAt).format('YYYY-MM-DD')}</p>
                <ConfirmButton
                    confirmationMsg="¿Eliminar empresa?"
                    disabled={working}
                    onClick={handleDeleteEmpresa}>
                    Eliminar</ConfirmButton>
                <br/>
                <br/>
                <PlanPicker value={instance.planId} onChange={handlePlanChange} disabled={working}/>
            </div>
            <Typography variant="h5" gutterBottom>
                Empleados
            </Typography>
            <div>
                <Cierre empresaId={props.match.params.id} start={query.start} end={query.end}/>
            </div>
            <div>
                <IconButton onClick={e => changeMonth(-1)}>
                    <ArrowBackIcon/>
                </IconButton>
                <CurrentMonth date={query.start}/>
                <IconButton onClick={e => changeMonth(1)}>
                    <ArrowForwardIcon/>
                </IconButton>
            </div>         
            <Chart title={'Activos'}  data={data} xKey="day" yKey="completados"/>
            <Chart title={'En proceso'}  data={data} xKey="day" yKey="enProceso"/>
            <Chart title={'Bajas'}  data={data} xKey="day" yKey="bajas"/>
        </div>
    )
}

export default withStyles(styles)(Instance)