

import React, { useState, useEffect } from 'react';
import Select from '@material-ui/core/Select';
import MenuItem from '@material-ui/core/MenuItem';
import moment from 'moment'

function DayPicker({date, style, day, pastMonth, onDayChange}) {   
    
    const [daysInMonth, setDaysInMonth] = useState([])

    useEffect(() => {      
        let d = moment(date);
        if(pastMonth) {
            d = d.month(d.month() - 1);
        }        
        const count = d.daysInMonth();
        const month = d.month();
        const year = d.year();

        const values = Array.from(Array(count).keys()).map(i => moment([year, month, i + 1]).format('YYYY-MM-DD'));
        if(pastMonth) {
            values.push(moment([month !== 11 ? year : year + 1, month != 11 ? month + 1 : 0, 1]).format('YYYY-MM-DD'));
        }
        setDaysInMonth(values)
    }, [date])

    function handleChange(event) {
        onDayChange(event.target.value)
    }

    return (
        <div>
            <Select
                style={style}
                value={day}
                onChange={handleChange}>
                {
                    daysInMonth.map(d => (
                        <MenuItem key={d} value={d}>{d}</MenuItem>
                    ))
                }
            </Select>
        </div>
    )

}

export default DayPicker;