import React, { useState } from 'react';
import axios from 'axios';
import {withStyles} from '@material-ui/core/styles'
import { Typography } from '@material-ui/core'
import {  TextField, Button } from '@material-ui/core'
import Config from './shared/Config'

const styles = theme => ({
    root: {
        width: '80%',
        margin: 'auto',
        marginTop: '50px'
    },
    form: {
        maxWidth: '400px',
        margin: 'auto'
    }
})

function Login(props) {

    const [loginData, setLoginData] = useState({
        username: '',
        password: ''
    })
    const [working, setWorking] = useState(false)
    const [error, setError] = useState('')

    const {classes} = props;

    async function login(e) {
        e.preventDefault();
        try {
            setWorking(true)
            const response = await axios.post(`${Config.API_URL}/auth/login`, {...loginData})
            localStorage.setItem('token', response.data.token);
            props.history.push('/')            
        } catch(e) {
            setWorking(false)
            setError(e.response.data);
        }        
    }

    return (
        <div className={classes.root}>
            <Typography variant="h3" gutterBottom>Integra RH</Typography>
            <Typography variant="h5" gutterBottom>Inicio de sesión</Typography>
            <form className={classes.form}
                onSubmit={login}>
                <TextField label="Usuario"
                    required
                    fullWidth
                    margin="normal"
                    value={loginData.username}
                    onChange={e => setLoginData({...loginData, username: e.target.value})}/>
                <TextField label="Contraseña"
                    required
                    fullWidth
                    margin="normal"
                    type="password"
                    onChange={e => setLoginData({...loginData, password: e.target.value})}/>
                <Button fullWidth 
                    disabled={working}
                    type="submit"
                    margin="normal"
                    color="primary">
                    Iniciar sesión
                </Button>
                { 
                    error ? 
                    <p style={{color:'red', textAlign:'center'}}>
                        {error.charAt(0).toUpperCase() + error.slice(1)}
                    </p> :
                    null
                }
            </form>
        </div>
    )

}

export default withStyles(styles)(Login)