import React, { useState, useEffect } from 'react';
import axios from 'axios';
import Config from '../Config'

function useInstance(instanceId) {

    const [instance, setInstance] = useState({
        planId:1
    })

    useEffect(() => {        
        const fetchInstance = async () => {
            const result = await axios.get(`${Config.API_URL}/empresas/${instanceId}`);
            setInstance(result.data)
        }
        fetchInstance();        
    }, [instanceId])

    return [instance, setInstance];
}

export default useInstance