import React, { useState, useEffect } from 'react';
import axios from 'axios';
import moment from 'moment'
import { Typography } from '@material-ui/core'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import { makeStyles } from '@material-ui/core/styles';
import {saveAs} from 'file-saver';

import Config from '../shared/Config'
import Money from '../shared/Money'
import useInstance from '../shared/hooks/useInstance'

const useStyles = makeStyles(theme => ({
  root: {
      width: '80%',
      margin: 'auto',
      marginTop: '50px'
  },
  table: {
    minWidth: 650,
  },
  header: {
      marginBottom: '20px'
  }
}));


function CierreDetail({match}) {

    const [instance, setInstance] = useInstance(match.params.empresaId);    
    const [cierreData, setCierreData] = useState({
        empleados: [],
        total:0
    });
    const classes = useStyles();

    async function fetchCierreData() {
        const result = await axios.get(`${Config.API_URL}/cierres/data?id=${match.params.cierreId}`);
        setCierreData(result.data);
    }
    
    useEffect(() => {
        fetchCierreData();
    }, [match.params.cierreId])

    async function generateXlsx() {
        const result = await axios.get(`${Config.API_URL}/cierres/data/xlsx?id=${match.params.cierreId}`, {
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
                'Accept': 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
            }
        })
        
        saveAs(new Blob([new Uint8Array(result.data)]), `Cierre ${instance.name}.xlsx`, {type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'})
    }

    return (        
        <div className={classes.root}>
            <div className={classes.header}>
                <Typography variant="h3" gutterBottom>
                    {instance.name}
                </Typography>
                <p>Generado el: {moment(cierreData.createdAt).format('YYYY-MM-DD')}</p>
                <p>Fecha inicio: {moment(cierreData.fechaInicio).format('YYYY-MM-DD')}</p>
                <p>Fecha cierre: {moment(cierreData.fechaCierre).format('YYYY-MM-DD')}</p>
                <p>Por empleado: <Money>{cierreData.porEmpleado}</Money></p>
                <Button 
                style={{margin: '0 10px'}}
                variant="contained" color="primary" 
                onClick={generateXlsx}>Exportar a excel</Button>
            </div>
            <Paper>
                <Table className={classes.table}>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nombre</TableCell>
                            <TableCell>Estatus</TableCell>
                            <TableCell>Fecha Alta</TableCell>
                            <TableCell>Fecha Baja</TableCell>
                            <TableCell>Fecha Eliminado</TableCell>
                            <TableCell>Dias</TableCell>
                            <TableCell>Total</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {
                            cierreData.empleados.map(e => (
                                <TableRow key={e.id}>
                                    <TableCell>{e.name}</TableCell>
                                    <TableCell>{e.status}</TableCell>
                                    <TableCell>{e.fecha}</TableCell>
                                    <TableCell>{e.fechaBaja ? e.fechaBaja : '-'}</TableCell>
                                    <TableCell>{e.fechaEliminado ? e.fechaEliminado : '-'}</TableCell>
                                    <TableCell>{e.dias}</TableCell>
                                    <TableCell>
                                        <Money>{e.total}</Money>
                                    </TableCell>
                                </TableRow>
                            ))
                        }
                        <TableRow>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell></TableCell>
                            <TableCell>TOTAL</TableCell>
                            <TableCell>
                                <Money>{cierreData.total}</Money>
                            </TableCell>
                        </TableRow>
                    </TableBody>
                </Table>
            </Paper>
        </div>
    )
}

export default CierreDetail;